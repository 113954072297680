var loadNews = ()=>{
const newsList = document.querySelector(".js-news-list");
const template = '<li class="top-news__list-item"><article class="news-item"><div class="news-item__date">{{publish_date}}</div><a href="{{link}}" class="news-item__title">{{title}}</a></article></li>';

fetch('https://kk-takushin.sakura.ne.jp/list.php')
.then((response) => {
    if (!response.ok) {
        throw new Error();
    }
    return response.json();
})
.then((res) => {
   
    res.data.filter(elm => elm.value.view_flg ).forEach(element => {
        const line = template.replace('{{publish_date}}',dayjs(element.value.publish_date).format('YYYY年MM月DD日'))
        .replace('{{title}}',element.value.title)
        .replace('{{link}}',['/news.html',element.id].join('#'));
        newsList.insertAdjacentHTML('beforeend',line);
    });
})
.catch((reason) => {
    // エラー
});
}
window.addEventListener("load", (event) => {
	loadNews();
}, false);